<template>
    <div >

        <div>
            <b-button v-b-toggle.collapse-1 style="background-color: #ededed !important; border: none !important; color: black !important; text-align: left !important; font-weight: bold; font-size: 18px;" block class="p-1">Kako zbiram točke?</b-button>


            <b-collapse id="collapse-1" class="mt-2" accordion="my-accordion" visible >
                <div class="grid-container pt-2" style="background-color: #ededed">
                    <div style="margin-top: 1rem; margin-bottom: 1rem; background-color: #ededed">
                        <p>Z registracijo na spletnem mestu <a href="www.esoseska.si">www.esoseska.si</a> uporabnik prične z zbiranjem točk. Točke se pridobivajo z rednim plačilom upravnikovega mesečnega računa/razdelilnika, prijavo na e-račun, oddanim oglasom na tržnici in nakupom v spletni trgovini eSoseske.</p>
                        <p>Ko uporabnik pridobi določeno število točk, se le te pretvorijo v kupon. Največje ugodnosti so določene s kuponom. Kuponi se lahko dodeljujejo tudi ob posebnih priložnostih/tržnih akcijah.</p>
                    </div>
                </div>
            </b-collapse>
        </div>

        <div class="mt-1">
            <b-button v-b-toggle.collapse-2 style="background-color: #ededed !important; border: none !important; color: black !important; text-align: left !important; font-weight: bold; font-size: 18px;" block class="p-1">Kako lahko unovčim zbrane točke?</b-button>


            <b-collapse id="collapse-2" class="mt-2" accordion="my-accordion">
                <div class="grid-container pt-2" style="background-color: #ededed">
                    <div style="margin-top: 1rem; margin-bottom: 1rem; background-color: #ededed">
                        <p>Ko uporabnik doseže skupno število <span class="blue-text">300 točk</span>, se te pretvorijo v <span class="blue-text">en (1) kupon</span>, ki ga uporabnik lahko izkoristi za cenejši nakup izdelkov v posebnih akcijskih ponudbah. Večje število doseženih točk, hkrati pomeni več pridobljenih kuponov in s tem možnost nakupa akcijskega artikla po posebni ceni.</p>
                        <p>Čas veljavnosti točk in kuponov in njihovo koriščenje je 12 mesecev od datuma prejema. Po tem obdobju se predhodno pridobljene točke izbrišejo.</p>
                        <p>Preverite trenutno ponudbo izdelkov za katere lahko unovčite kupone <a href="https://esoseska.si/izdelki/660d3f8b689d1f7f2f4d795a">tukaj</a></p>
                    </div>
                </div>
            </b-collapse>
        </div>

    </div>
</template>

<script>
    import { BCollapse, VBToggle, BButton} from 'bootstrap-vue'
    import Vue from 'vue'

    export default {
        components: {BCollapse, BButton},
        props: {
            type:{
                type: String,
                required: true
            },
            data:{
                type: Array
            }
        },
        created() {
            Vue.directive('b-toggle', VBToggle)
        }
    }
</script>

<style scoped>
.grid-item {
  background-color: #ededed;
  font-size: 16px;
  text-align: center;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 450px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    background-color: #ededed;
  }
  .grid-container-vertical {
    display: grid;
    grid-template-rows: auto auto;
    background-color: #ededed;
  }
}

@media screen and (min-width: 950px) {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: none;
    background-color: #ededed;
    padding: 10px;
  }
  .grid-container-vertical {
    display: grid;
    grid-template-rows: minmax(6rem,auto) 2rem;
    background-color: #ededed;
  }
}

.blue-text {
  color: #72A5D8;
}
.black-text{
  color: black;
  font-weight: bolder;
  font-size: medium;
}
.whole_word{
  white-space: nowrap;
}
</style>