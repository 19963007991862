<template>
    <app-collapse style="background-color: #ededed; font-weight: bold;" v-if="data">
        <app-collapse-item style="background-color: #ededed;" title="Kako zbiram točke?" header-c-s-s="font-size: large; color: black; font-weight: bolder;" header-separator="height: 3px; background-color: #FFFFFF">
            <div class="grid-container pt-2" style="background-color: #ededed">
                <div class="grid-item grid-container-vertical" v-for="item in data" :key="'item_' + item.internal_id">
                    <p class="blue-text">{{item.reason}}</p>
                    <p class="black-text whole_word">{{item.points}} točk</p>
                </div>
            </div>
        </app-collapse-item>
    </app-collapse>
</template>

<script>
    import appCollapse from '@core/components/app-collapse/AppCollapse'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'

    export default {
        components: {AppCollapseItem, appCollapse},
        props: {
            data:{
                type: Array,
                required: true
            }
        }
    }
</script>

<style scoped>
.grid-item {
    background-color: #ededed;
    font-size: 16px;
    text-align: center;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

@media screen and (max-width: 450px) {
    .grid-container {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto;
        background-color: #ededed;
    }
    .grid-container-vertical {
        display: grid;
        grid-template-rows: auto auto;
        background-color: #ededed;
    }
}

@media screen and (min-width: 950px) {
    .grid-container {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        grid-template-rows: none;
        background-color: #ededed;
        padding: 10px;
    }
    .grid-container-vertical {
        display: grid;
        grid-template-rows: minmax(6rem,auto) 2rem;
        background-color: #ededed;
    }
}

.blue-text {
    color: #72A5D8;
    font-weight: bolder;
    font-size: 1rem;
}
.black-text{
    color: black;
    font-weight: bolder;
    font-size: medium;
}
.whole_word{
    white-space: nowrap;
}
</style>